@import "../main/abstracts/index";

.section--countdown-custom{

    body[data-template="home"] .page__main > &{

        header{
            margin-bottom: 1.5rem;

            @include media-breakpoint-up(sm) {
                margin-bottom: 2.5rem;
            }

            @include media-breakpoint-up(lg) {
                margin-bottom: 3rem;
            }
        }
    }

    header{

        h2{

            .subheadline{
                @include fluid-type(map-get($grid-breakpoints, sm), map-get($grid-breakpoints, xxl), 18px, 20px);
                font-weight: $font__weight--normal;
                color: $color__black;
                margin-left: 35px;
            }

            @include media-breakpoint-down(md) {

                .subheadline{
                    margin-left: 0;
                    margin-top: 6px;
                }

                span{
                    display: block;
                }
            }
        }
    }

    .countdown{

        &__list{
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            list-style-type: none;
            margin: 0;
            padding: 0;

            @include media-breakpoint-up(sm) {
                justify-content: space-between;
            }

            @include media-breakpoint-up(md) {
                justify-content: center;
            }

            @include media-breakpoint-up(lg) {
                justify-content: space-between;
            }
        }

        &__list-item{
            width: 70%;
            min-width: 330px;
            max-width: 100%;
            margin-bottom: 35px;

            &:first-child,
            &:nth-child(2){
                margin-bottom: 35px;
            }

            @media (max-width: 400px) {
                min-width: 270px;
            }

            @include media-breakpoint-up(sm) {
                width: 46%;
                min-width: unset;
                max-width: unset;
                margin-bottom: 0;
            }

            @include media-breakpoint-up(md) {
                width: 40%;
                min-width: 350px;
            }

            @include media-breakpoint-up(lg) {
                width: 22%;
                min-width: unset;

                &:first-child,
                &:nth-child(2){
                    margin-bottom: 0;
                }
            }
        }

        &__item{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;
            width: 100%;

            @include media-breakpoint-up(lg) {
                width: 80%;
            }

            h3{
                text-align: center;
                color: $color__primary;
                font-weight: $font-weight-normal;
                @include fluid-type(map-get($grid-breakpoints, sm), map-get($grid-breakpoints, xxl), 19px, 27px);
            }
        }

        &__image{
            height: 80px;
            width: auto;
            margin-bottom: 12px;
            filter: $filter__primary;
        }

        &__line{
            content: "";
            position: relative;
            display: block;
            height: 2px;
            width: 55px;
            margin-top: 5px;
            margin-bottom: 18px;
            border-radius: 3px;
            background-color: $color__black;
        }

        &__number{
            color: $color__primary-2;
            font-weight: $font-weight-bold;
            @include fluid-type(map-get($grid-breakpoints, sm), map-get($grid-breakpoints, xxl), 28px, 45px);
        }
    }
}
